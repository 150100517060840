<!--
 * @Description: 运维管理系统 设备管理 道闸设备 barrierGate
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="道闸编号">
              <el-input v-model="searchForm.deviceId"
                        clearable
                        placeholder="请输入道闸编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="道闸名称">
              <el-input v-model="searchForm.deviceName"
                        clearable
                        placeholder="请输入道闸名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="道闸地址">
              <el-input v-model="searchForm.address"
                        clearable
                        placeholder="请输入道闸地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="厂商">
              <el-input v-model="searchForm.vendor"
                        clearable
                        placeholder="请输入厂商"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="所在停车场">
              <el-select v-model="searchForm.parkId"
                         :clearable=true
                         placeholder="全部">
                <el-option v-for="item in parkingList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryButton">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>道闸设备</span>
        <span class="tableTitleButton topButton">
          <el-button type="success"
                     @click="handleClickAdd">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px">
            <a :href="exportFile"
               download=""
               style="color:white">导 出</a>
          </el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  :tableFun="tableFun"
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button @click="handleClickEdit(scope.row)"
                               type="text"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               @click="handleClickDelete(scope.row)"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增道闸"
               :visible.sync="addDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="addFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="addFormList.deviceId"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="addFormList.deviceName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkId">
              <el-select clearable
                         v-model="addFormList.parkId"
                         placeholder="请选择"
                         class="dt-form-width"
                         @change="parkingChange">
                <el-option v-for="(item, index) in parkingList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出入口名称"
                          prop="passagewayId">
              <el-select clearable
                         v-model="addFormList.passagewayId"
                         placeholder="请先选择停车场"
                         class="dt-form-width">
                <el-option v-for="(item, index) in passagewayIdList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="addFormList.contact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="addFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input v-model="addFormList.longitude"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input v-model="addFormList.latitude"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="addFormList.vendor"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="addFormList.address"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addFormList.remark"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickAddConfirm('addFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="addDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改道闸"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="editFormList"
               :rules="rules"
               :inline="true"
               ref="editFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="editFormList.deviceId"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="editFormList.deviceName"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkId">
              <el-select clearable
                         v-model="editFormList.parkId"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in parkingList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出入口名称"
                          prop="passagewayId">
              <el-select clearable
                         v-model="editFormList.passagewayId"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in passagewayIdList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="editFormList.contact"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="editFormList.contactPhoneNumber"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input v-model="editFormList.longitude"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input v-model="editFormList.latitude"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="editFormList.vendor"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="editFormList.address"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="editFormList.remark"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickEditConfirm('editFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="editDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="道闸详情"
               :visible.sync="detailDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="detailFormList"
               :rules="rules"
               :inline="true"
               ref="detailFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号">
              <el-input v-model="detailFormList.deviceId"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称">
              <el-input v-model="detailFormList.deviceName"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="停车场名称">
              <el-select clearable
                         v-model="detailFormList.parkId"
                         placeholder="请选择"
                         class="dt-form-width"
                         disabled>
                <el-option v-for="(item, index) in parkingList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出入口名称">
              <el-select clearable
                         v-model="detailFormList.passagewayName"
                         placeholder="请选择"
                         disabled
                         class="dt-form-width">
                <el-option v-for="(item, index) in passagewayIdList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人">
                <el-input v-model="detailFormList.contact"
                          class="dt-form-width"
                          readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话">
                <el-input v-model="detailFormList.contactPhoneNumber"
                          class="dt-form-width"
                          readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="经度坐标">
                <el-input v-model="detailFormList.longitude"
                          class="dt-form-width"
                          readonly
                          oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度坐标">
                <el-input v-model="detailFormList.latitude"
                          class="dt-form-width"
                          readonly
                          oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="12">
            <el-form-item label="所属厂商">
              <el-input v-model="detailFormList.vendor"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备地址">
              <el-input v-model="detailFormList.address"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="detailFormList.remark"
                        class="dt-form-width"
                        readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="info"
                     class="popBtn"
                     @click="detailDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary">
            <a :href="templateDl"
               class="download"
               download=""
               style="color: #ffffff;text-decoration:none">模板下载</a>
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  //监听属性 类似于data概念
  computed: {
    exportFile: function () {
      return this.$downloadBaseUrl + "passagewayGate/download";
    }
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'deviceId',
          label: '道闸机编号',
        },
        {
          prop: 'deviceName',
          label: '道闸机名称',
        },
        {
          prop: 'address',
          label: '道闸机地址',
        },
        {
          prop: 'parkName',
          label: '归属停车场',
        },
        {
          prop: 'vendor',
          label: '厂商',
        }
      ],
      tableList: {
        list: [],
      },
      searchForm: {},  // 顶部查询表单
      pageNum: 1,
      pageSize: 15, // 每页的数据条数

      addFormList: {},// 新增表单
      addDialogVisible: false, // 新增弹窗

      detailFormList: {},// 详情表单
      detailDialogVisible: false, // 详情弹窗

      editFormList: {},// 详情表单
      editDialogVisible: false, //修改弹窗

      massDeleteList: [], // 批量删除绑定

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",

      rules: {
        parkId: [{ required: true, message: '请选择停车场', trigger: 'change' }],
        passagewayId: [{ required: true, message: '请选择出入口', trigger: 'change' }],
        deviceId: [{ required: true, message: '请输入设备编号', trigger: 'blur' }],
        deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入设备地址', trigger: 'blur' }],
        vendor: [{ required: true, message: '请输入设备厂商', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入经度坐标', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入纬度坐标', trigger: 'blur' }],
      },
      parkingList: [],// 停车场列表
      passagewayIdList: [],// 出入口列表
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.templateDl = this.$downloadBaseUrl + "passagewayGate" + "/downloadTemplate";
    this.queryTableList()
    this.queryParkList()
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "passagewayGate/upload";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 默认渲染
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$barrierGate.queryPassagewayGateList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryButton () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.queryTableList()
    },
    // 新增弹窗
    handleClickAdd () {
      this.addDialogVisible = true
      this.addFormList = {}
    },
    // 新增确认提交
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$barrierGate.insertPassagewayGate(this.addFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '新增成功', type: 'success' });
              this.addDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })
    },
    //查看详情
    handleClickDetail (row) {
      this.detailFormList = row
      this.detailDialogVisible = true
    },
    //修改
    handleClickEdit (row) {
      this.editFormList = {}
      this.parkingChange(row.parkId)
      this.editFormList = row
      this.editDialogVisible = true
    },
    // 修改
    handleClickEditConfirm () {
      this.$barrierGate.updatePassagewayGate(this.editFormList).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '修改成功', type: 'success' });
          this.editDialogVisible = false
          this.queryTableList()
        }
      })
    },
    // 删除请求
    handleClickDelete (row) {
      this.$confirm('是否删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$barrierGate.delPassagewayGate([{ deviceId: row.deviceId, parkId: row.parkId }]).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      });
    },
    //表格多选按钮
    handleChange (val) {
      let arr = []
      val.forEach((item) => {
        arr.push({ deviceId: item.deviceId, parkId: item.parkId })
      })
      this.massDeleteList = arr
    },
    //批量删除
    massDeletion () {
      console.log(this.massDeleteList.length);
      if (this.massDeleteList.length === 0) {
        this.$message({ showClose: true, message: '至少勾选一条记录', type: 'error' });
      } else {
        this.$confirm('确定批量删除道闸?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = this.massDeleteList
          this.$barrierGate.delPassagewayGate(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },
    // 获取停车场
    queryParkList () {
      let info = {
        columnName: ["park_id", "park_name"],
        tableName: "tb_park",
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkingList = response.resultEntity
      })
    },
    // 弹出框停车场发生变化
    parkingChange (val) {
      let info = {
        columnName: ["passageway_id", "passageway_name"],
        tableName: "tb_passageway",
        "whereStr": [
          {
            "colName": "park_id",
            "value": val
          }
        ]
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.passagewayIdList = res.resultEntity
      })
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    display: flex;
    flex-direction: column;
    .topFirstLine {
      display: flex;
      flex-direction: row;
    }
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 30px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
</style>
